@import '../styles/variables.css';

/* General NavBar Layout */
.navbar {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--background-primary);
  color: var(--text-primary);
  font-family: Arial, sans-serif;
  padding: 15px 0;
  border-top: 3px solid var(--link-default);
  border-bottom: 3px solid var(--link-default);
  position: sticky;
  top: 0;
  z-index: 1000;
}

/* Top Section with Logo, Slogan, and Menu Controls */
.navbar-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.slogan h1 {
  font-size: 2rem;
  font-weight: bold;
  color: #AF1E2D;
  margin: 0;
}

/* Hamburger Menu Button */
.hamburger-menu-btn {
  background: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: var(--link-default);
  display: flex;
}

/* Overlay for Navigation Links */
.nav-overlay {
  position: absolute;
  top: 100%;
  right: 1%;
  z-index: 1000;
  background: #ffffff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  min-width: 180px;
  max-width: 300px;
  text-align: left;
}

/* Navigation Links Styling for Overlay */
.nav-links {
  width: 100%;
  position: relative;
}

.nav-links .filter-heading {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--link-default);
  margin-top: 10px;
}

.nav-links .filter-option {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.nav-links input[type="checkbox"] {
  margin-right: 10px;
}

/* Close Menu Button for Overlay */
.close-menu-btn {
  background: transparent;
  border: none;
  font-size: 1.2rem;
  color: #df0000;
  position: absolute;
  top: 1px;
  right: 1px;
  cursor: pointer;
}

/* Responsive adjustments for the overlay */
@media (max-width: 768px) {
  .nav-overlay {
    max-width: 90%;
    right: 5%;
  }
}

@media (min-width: 769px) {
  .nav-overlay {
    max-width: 600px;
  }
}

/* Title Link Styling */
.navbar-title-link {
  text-decoration: none;
  color: #AF1E2D;
  transition: color 0.3s ease;
}

.navbar-title-link:hover,
.navbar-title-link:focus {
  color: #FF4C4C;
}
