:root {
  /* Brand Colors */
  --habs-blue: #192168;
  --habs-red: #AF1E2D;
  --bright-red: #FF4C4C;
  
  /* Common Colors */
  --white: #ffffff;
  --black: #000000;
  --gray-light: #f5f5f5;
  --gray-medium: #cccccc;
  
  /* Functional Colors */
  --background-primary: var(--gray-light);
  --text-primary: var(--black);
  --link-default: var(--black);
  --link-hover: var(--habs-red);
  --focus-outline: var(--bright-red);
}