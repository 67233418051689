/* Updated for HomePage component */
.homepage {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensure it spans the full height of the viewport */
}

/* Add this to ensure full coverage */
body {
    background-color: #f5f5f5; /* Light grey background */
}

.feed-container {
    flex-grow: 1; /* Allow the feed to take up remaining space */
}