/* Modal Overlay */
.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8); /* Darker overlay for emphasis */
  z-index: 1000;
}

.video-modal-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
}

.video-modal-content {
  position: relative;
  width: 90%; /* Increase width to take more space */
  max-width: 1000px; /* Set a max-width to avoid going too large */
  background: none;
  padding: 0;
  border-radius: 0;
  overflow: hidden;
}

.video-modal iframe {
  width: 100%;
  height: 80vh; /* Set height to take 80% of viewport height */
  border: none;
}

.video-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: #fff;
  cursor: pointer;
}

/* Responsive Design for Tablet View */
@media (min-width: 769px) and (max-width: 1024px) {
  .video-modal-content {
    width: 95%; /* Slightly wider on tablet */
  }

  .video-modal iframe {
    height: 70vh; /* Adjust iframe height on tablet for better fit */
  }

  .video-modal-close {
    font-size: 1.3rem; /* Slightly smaller close button */
  }
}

/* Mobile View */
@media (max-width: 768px) {
  .video-modal-content {
    width: 100%; /* Full width on mobile */
    max-width: 500px; /* Max width to prevent overflow on small screens */
  }

  .video-modal iframe {
    height: 60vh; /* Reduced height for mobile */
  }

  .video-modal-close {
    font-size: 1.2rem; /* Smaller close button on mobile */
    top: 8px;
    right: 8px;
  }
}

/* Extra Small Mobile View */
@media (max-width: 480px) {
  .video-modal-content {
    max-width: 100%; /* Take full width on very small screens */
  }

  .video-modal iframe {
    height: 50vh; /* Reduce iframe height for extra small screens */
  }

  .video-modal-close {
    font-size: 1rem; /* Even smaller close button */
    top: 5px;
    right: 5px;
  }
}
