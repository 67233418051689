.feed-container {
  flex-direction: column;
  min-height: 100vh;
  display: flex;
  padding: 20px;
  background-color: var(--background-color);
}
 
.feed-content {
  display: flex;
  width: 100%;
  gap: 24px;
  max-width: 1400px;
  margin: 0 auto;
}
 
.feed-sidebar {
  width: 300px;
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  height: fit-content;
  position: sticky;
  top: 20px;
}
 
.feed-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-content: start;
}
 
.feed-card {
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  transition: all 0.2s ease;
  cursor: pointer;
  border: 1px solid #000;
  width: 75%;
  margin: 0 auto;
}
 
.feed-card.article {
  flex-direction: row;
  display: flex;
  height: 160px;
}
 
.feed-card.video {
  flex-direction: column;
}
 
.feed-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-color: var(--habs-red);
}
 
.feed-thumbnail-wrapper {
  position: relative;
  background: #fff;
}
 
.video .feed-thumbnail-wrapper {
  width: 100%;
  padding-top: 56.25%;
}
 
.article .feed-thumbnail-wrapper {
  width: 160px;
  flex-shrink: 0;
}
 
.feed-thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: #fff;
}
 
.source-tag {
  position: absolute;
  top: 12px;
  left: 12px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
}
 
.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 64px;
  height: 64px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
}
 
.feed-card:hover .play-button {
  background-color: var(--habs-red);
}
 
.play-icon {
  width: 0;
  height: 0;
  border-left: 24px solid #fff;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  margin-left: 4px;
}
 
.feed-info {
  padding: 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
}
 
.feed-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 8px;
  max-height: 2.8em;
  overflow: hidden;
  position: relative;
  padding-right: 1em;
}
 
.feed-meta {
  font-size: 14px;
  color: #666;
  margin-top: auto;
}
 
.author {
  font-weight: 500;
}
 
.separator {
  margin: 0 6px;
}

.loading-spinner {
  opacity: 0;
  animation: fadeIn 0.3s ease forwards;
  position: relative;
  height: 40px;
  width: 40px;
}
 
.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid var(--habs-red);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
 
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
 
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
 
@media (max-width: 1024px) {
  .feed-content {
    gap: 20px;
  }
  
  .feed-sidebar {
    width: 260px;
  }
}
 
@media (max-width: 768px) {
  .feed-container {
    padding: 16px;
  }
 
  .feed-content {
    flex-direction: column;
  }
 
  .feed-sidebar {
    width: 100%;
    position: static;
  }
 
  .feed-card.article {
    height: auto;
  }
 
  .article .feed-thumbnail-wrapper {
    width: 120px;
  }
}
 
@media (max-width: 480px) {
  .feed-container {
    padding: 12px;
  }
 
  .article .feed-thumbnail-wrapper {
    width: 100px;
  }
}