.footer-container {
  background-color: #192168; /* Use your preferred color */
  color: #ffffff;
  padding: 5px 10px; /* Reduced padding to make the footer smaller */
  text-align: center;
  font-family: Arial, sans-serif;
  position: sticky; /* Make it stick to the bottom */
  bottom: 0; /* Fix to the bottom of the viewport */
  z-index: 100; /* Ensure it stays above the main content */
  margin-top: auto; /* Push footer to the bottom of the flex container */
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.footer-links a {
  color: #ffffff;
  text-decoration: none;
  font-size: 0.9rem;
}

.footer-links a:hover {
  text-decoration: underline;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
}

.social-icons a {
  color: #ffffff;
  font-size: 1.5rem; /* Adjust icon size */
  margin: 0 10px;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #cccccc;
  transform: scale(1.1); /* Slightly enlarge on hover */
}

.social-icons svg {
  width: 20px;
  height: 20px;
}

.copyright {
  margin-top: 20px;
  font-size: 0.8rem;
  color: #cccccc;
}

/* Responsive Design for Tablet View */
@media (min-width: 769px) and (max-width: 1024px) {
  .footer-container {
    padding: 15px;
  }

  .footer-links {
    gap: 15px; /* Adjust gap for better fit on tablet */
  }

  .footer-links a {
    font-size: 0.85rem; /* Slightly smaller font for tablet */
  }

  .social-icons {
    gap: 10px; /* Reduce gap between icons on tablet */
  }

  .social-icons a {
    font-size: 1.3rem; /* Slightly smaller font size for icons */
    margin: 0 8px;
  }
}

/* Mobile View */
@media (max-width: 768px) {
  .footer-container {
    padding: 10px;
  }

  .footer-links {
    flex-direction: column;
    gap: 10px; /* Stack links vertically with reduced gap */
  }

  .footer-links a {
    font-size: 0.8rem; /* Smaller font for mobile */
  }

  .social-icons {
    gap: 8px; /* Smaller gap between icons */
  }

  .social-icons a {
    font-size: 1.1rem; /* Adjust icon size for mobile */
    margin: 0 5px;
  }
}

/* Extra Small Mobile View */
@media (max-width: 480px) {
  .footer-container {
    padding: 5px;
  }

  .footer-links {
    gap: 8px;
  }

  .footer-links a {
    font-size: 0.75rem;
  }

  .social-icons {
    gap: 6px;
  }

  .social-icons a {
    font-size: 1rem; /* Smaller icon size for extra small mobile */
    margin: 0 4px;
  }

  .copyright {
    font-size: 0.7rem;
    margin-top: 15px;
  }
}
