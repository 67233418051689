.banner {
  width: 100%;
  background-color: var(--habs-red);
  color: var(--white);
  text-align: center;
  padding: 8px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.banner:hover {
  transform: scale(1.05); /* Slightly enlarges the banner on hover */
  background-color: var(--bright-red);
}

.banner-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px; /* Add some side padding for better alignment on larger screens */
}

.banner-title,
.banner-subtitle {
  text-decoration: underline;
  text-decoration-color: var(--white);
  font-weight: bold;
  margin: 0;
}

.banner-title {
  font-size: 2rem;
}

.banner-subtitle {
  font-size: 1rem;
  margin-top: 5px;
}

/* Responsive Design for Tablet View */
@media (min-width: 769px) and (max-width: 1024px) {
  .banner-content {
    padding: 0 15px; /* Reduce side padding on tablet */
  }

  .banner-title {
    font-size: 1.75rem; /* Slightly smaller font size for title */
  }

  .banner-subtitle {
    font-size: 0.9rem; /* Slightly smaller font size for subtitle */
  }
}

/* Mobile View */
@media (max-width: 768px) {
  .banner {
    padding: 6px 0; /* Reduced padding for a shorter banner on mobile */
  }

  .banner-content {
    padding: 0 10px; /* Reduce padding further for mobile */
  }

  .banner-title {
    font-size: 1.5rem; /* Adjust title font size for mobile */
  }

  .banner-subtitle {
    font-size: 0.85rem; /* Adjust subtitle font size */
  }
}

/* Extra Small Mobile View */
@media (max-width: 480px) {
  .banner {
    padding: 5px 0; /* Minimize padding for very small screens */
  }

  .banner-content {
    padding: 0 5px; /* Further reduce padding on extra small screens */
  }

  .banner-title {
    font-size: 1.3rem; /* Smaller title font for extra small screens */
  }

  .banner-subtitle {
    font-size: 0.75rem; /* Smaller subtitle font */
  }
}
